/*
 * @Author: your name
 * @Date: 2021-12-07 17:55:06
 * @LastEditTime: 2021-12-07 18:45:34
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/ForecastShipping/index.js
 */
import httpService from "@/request";

// 预报计划下单
export function orderCreate(params) {
  return httpService({
    url: `/order/create`,
    method: "post",
    data: params,
  });
}
// 预报非计划下单
export function orderCreatePlan(params) {
  return httpService({
    url: `/order/create/plan`,
    method: "post",
    data: params,
  });
}
// 获取渠道类型 （级联选择）
export const getChannelList = (params = {}) => {
  return httpService({
    url: `/channel/select/type`,
    method: "post",
    data: params,
  });
};
// 公告列表
export function getAnnounceList(params) {
  return httpService({
    url: `/announce/list`,
    method: "post",
    data: params,
  });
}
// 公告详情
export function privateInfo(params) {
  return httpService({
    url: `/announce/info`,
    method: "post",
    data: params,
  });
}
// 最新公告
export function lasterAnnounce(params = {}) {
  return httpService({
    url: `/announce/latest`,
    method: "post",
    data: params,
  });
}
// 不在提醒
export function notRemind(params) {
  return httpService({
    url: `/announce/notRemind`,
    method: "post",
    data: params,
  });
}
// 获取运单号
export const getWaybillApi = (data = {}) => {
  return httpService({
    url: `/order/waybill/no/get`,
    method: "post",
    data,
  });
};
