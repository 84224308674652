<template>
  <!-- <a-table
    size="small"
    bordered
    :columns="columns"
    :data-source="tableData"
    :loading="loading"
    :scroll="{ x: 'max-content' }"
    :pagination="false"
  >
  </a-table> -->
  <div class="waybill-list">
    <a-row v-if="tableData.length">
      <a-col v-for="(item, index) in tableData" :key="index" :span="8">
        {{ item }}
      </a-col>
    </a-row>
    <a-empty v-else />
  </div>
</template>

<script>
import { getDetailsApi } from "@/api/waybillNoManagement";
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    passData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      columns: Object.freeze([
        {
          title: "运单号",
          dataIndex: "num",
          key: "num",
        },
      ]),
    };
  },
  created() {
    this.getData();
  },
  watch: {
    visible(v) {
      if (v) this.getData();
    },
  },
  methods: {
    getData() {
      this.loading = true;
      return getDetailsApi({ id: this.passData.id })
        .then((res) => {
          this.tableData = res.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.waybill-list {
  max-height: 450px;
  overflow-y: auto;
}
</style>
