<template>
  <div class="waybill-management-page">
    <div class="search-wrapper">
      <a-row>
        <a-col :span="4">
          <label for="#" style="margin-right: 8px; vertical-align: top"
            >运单号</label
          >
          <a-textarea
            style="width: 200px"
            v-model.trim="keyword"
            placeholder="请输入"
            :rows="4"
          />
        </a-col>
        <a-col :span="4">
          <a-button style="margin-right: 8px" type="primary" @click="getData"
            >查询</a-button
          >
          <a-button @click="resetHandle">重置</a-button>
        </a-col>
      </a-row>
    </div>
    <a-divider />
    <div class="operation-wrapper">
      <a-button type="primary" @click="getWaybillHandle">获取运单号</a-button>
    </div>
    <a-table
      size="small"
      bordered
      :columns="columns"
      :data-source="tableData"
      style="margin-top: 20px"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
    >
      <template slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
      </template>
      <template slot="action" slot-scope="record">
        <a class="btn-a" type="link" @click="detailsHandle(record)">查看详情</a>
      </template>
      <template slot="created_at" slot-scope="record">
        {{ record | dateFmatter }}
      </template>
    </a-table>
    <a-pagination
      v-model="searchForm.page"
      :pageSize.sync="searchForm.limit"
      style="margin-top: 16px; text-align: right"
      show-size-changer
      :default-current="1"
      :showTotal="(total) => `共 ${total} 条`"
      :total="searchForm.total"
      @showSizeChange="onShowSizeChange"
    />
    <!-- 弹出层 -->
    <a-modal
      v-model="dialogOpts.visible"
      :width="dialogOpts.width"
      :title="dialogOpts.title"
      :footer="null"
    >
      <component
        :is="dialogOpts.component"
        :visible.sync="dialogOpts.visible"
        :pass-data="dialogOpts.passData"
        @submit="dialogSubmitHandle"
      ></component>
    </a-modal>
  </div>
</template>

<script>
import { getListApi } from "@/api/waybillNoManagement";
import Details from "./details.vue";
import GetWaybill from "../ForecastShipping/firstStep/getWaybill.vue";
import dayjs from "dayjs";
import columns from "./data";
export default {
  name: "WaybillNoManagement",
  components: {
    GetWaybill,
    Details,
  },
  data() {
    return {
      keyword: "",
      searchForm: {
        waybillNo: undefined,
        waybillNos: [],
        page: 1,
        limit: 20,
        total: 0,
      },
      loading: false,
      tableData: [],
      dialogOpts: {
        visible: false,
        title: "",
        component: "",
        width: "520px",
        passData: {},
      },
      columns: Object.freeze(columns),
    };
  },
  created() {
    this.getData();
  },
  filters: {
    dateFmatter(val) {
      if (!val) return "-";
      return dayjs(val * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    getData() {
      this.loading = true;
      const text = this.keyword.replaceAll(/，|\n/g, ",").split(",");
      if (text.length > 1) {
        this.searchForm.waybillNos = text;
        this.searchForm.waybillNo = undefined;
      } else {
        this.searchForm.waybillNo = text[0];
        this.searchForm.waybillNos = [];
      }
      if (this.searchForm)
        return getListApi(this.searchForm)
          .then((res) => {
            this.tableData = res.data.list || [];
            this.searchForm.total = res.data.total || 0;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    onShowSizeChange() {
      this.getData();
    },
    resetHandle() {
      this.keyword = "";
      this.searchForm = {
        waybillNo: undefined,
        waybillNos: [],
        page: 1,
        limit: 20,
      };
      this.getData();
    },
    detailsHandle(row) {
      console.log("aaaa");
      this.dialogOpts.title = "查看详情";
      this.dialogOpts.width = "520px";
      this.dialogOpts.passData = row;
      this.dialogOpts.component = "Details";
      this.dialogOpts.visible = true;
    },
    getWaybillHandle() {
      this.dialogOpts.title = "获取运单号";
      this.dialogOpts.width = "520px";
      this.dialogOpts.component = "GetWaybill";
      this.dialogOpts.visible = true;
    },
    dialogSubmitHandle() {
      this.dialogOpts.visible = false;
      this.getData();
    },
  },
};
</script>
<style lang="less" scoped>
.waybill-management-page {
  padding: 12px;
}
/deep/.ant-form {
  min-width: 1300px;
}

/deep/.ant-modal-footer {
  border-top: none;
}

/deep/.ant-modal-header {
  border-bottom: none;
}
</style>
