import httpService from "@/request";

// 列表
export function getListApi(data = {}) {
  return httpService({
    url: `/order/waybill/pre/save`,
    method: "post",
    data,
  });
}
// 详情
export function getDetailsApi(data = {}) {
  return httpService({
    url: `/order/waybill/pre/save/info`,
    method: "post",
    data,
  });
}
