export default [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "获取数量",
    dataIndex: "num",
    key: "num",
  },
  {
    title: "操作人",
    dataIndex: "customer_name",
    key: "customer_name",
  },
  {
    title: "操作时间",
    dataIndex: "created_at",
    key: "created_at",
    scopedSlots: {
      customRender: "created_at",
    },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
  },
];
