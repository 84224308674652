<template>
  <a-form-model
    ref="formDataRef"
    layout="horizontal"
    :model="formData"
    :footer="null"
    :label-col="{ span: 2 }"
    :wrapper-col="{ offset: 2 }"
  >
    <a-form-model-item
      label="获取运单号"
      prop="num"
      :rules="[{ required: true, message: '请输入要获取运单号的数量!' }]"
    >
      <a-input-number
        style="width: 360px"
        v-model="formData.num"
        :max="999"
        :precision="0"
        placeholder="请输入要获取运单号的数量"
      />
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ offset: 6 }">
      <a-button style="margin-right: 16px" @click="cancelHandle">取消</a-button>
      <a-button type="primary" @click="submitHandle"> 确认 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { getWaybillApi } from "@/api/ForecastShipping";
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      formData: {
        num: undefined,
      },
    };
  },
  watch: {
    visible(v) {
      if (!v) {
        this.$refs.formDataRef.resetFields();
      }
    },
  },
  methods: {
    submitHandle() {
      this.$refs.formDataRef.validate((valid) => {
        if (valid) {
          getWaybillApi(this.formData).then(() => {
            this.$message.success("操作成功!");
            this.$emit("submit");
          });
        } else {
          return false;
        }
      });
    },
    cancelHandle() {
      this.$emit("update:visible", false);
    },
  },
};
</script>
<style lang="less" scoped></style>
